export const colorVariables = {
  primaryColor: '#2e7d32',
  infoColor: '#D8FFDC',
  separatorColor: '#E6E7E6',
  cardColor: '#F5F9F5',
  footerColor: '#F3F3F3',
  footerRightsColor: '#E6E7E6',
  textDescription: '#666D66',
  borderColor: '#E1EAE1',
};

export const headerHeight = 70;
export const footerHeight = 80;
export const footerRightsHeight = 55;
export const searchHeadHeight = 150;
export const vendorHeightFooter = 96;
export const headerControlsHeight = 40;

export const searchResultsHeight = 1050;
export const breakCrumbsHeight = 137;
export const servicesListHeight = 225;

export const pageLimit = 20;

export const defaultMetaTitle = {
  title: 'Commercial Real Estate Services',
  description: 'Find top Commercial Real Estate services providers in your area to support your next project',
};

export const miles = ['25', '50', '100', '200'];
